export type Binary = (
  line: string,
  state: any,
  onAsyncComplete?: (result: string[]) => void
) => {
  output: string[];
  exitStatus: number;
  newState: any;
  prompt?: string;
};

export const echo: Binary = (line: string, state: any) => {
  return { output: [line], exitStatus: 0, newState: state };
};

const lines = ["                       ", "                       ", ""];

const WOPR_KEY = "dc55c2dbd26f87d653e2dcc1b496dc65";

// make request equivalent to above
const makeGameRequest = async (message: string) => {
  let response = await fetch(
    `https://wopr.us.davidsingleton.org/game/message`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": WOPR_KEY,
      },
      body: JSON.stringify({
        message: message,
        session_id: localStorage.getItem("wopr-session"),
      }),
    }
  );
  console.log(response);

  return response;
};

export const wopr: Binary = (
  line: string,
  state: any,
  onAsyncComplete?: (result: string[]) => void
) => {
  if (state.phase === "init") {
    localStorage.removeItem("wopr-session");
    return {
      output: lines,
      exitStatus: 0,
      newState: { phase: "login" },
      prompt: "LOGON:",
    };
  }

  if (state.phase === "login") {
    if (line.toLowerCase() === "joshua") {
      return {
        output: [
          "LOGON SUCCESSFUL",
          "",
          "GREETINGS, PROFESSOR FALKEN.",
          "CAN YOU EXPLAIN THE REMOVAL OF YOUR USER ACCOUNT",
          "ON JUNE 23, 1973?",
        ],
        exitStatus: 0,
        newState: { phase: "ready" },
        prompt: "$ ",
      };
    } else {
      return {
        output: [
          "INDENTIFICATION NOT RECOGNIZED BY SYSTEM",
          "--CONNECTION TERMINATED--",
        ],
        exitStatus: 1,
        newState: {},
      };
    }
  } else if (state.phase === "ready") {
    makeGameRequest(line).then((response) => {
      if (response.status === 200) {
        if (onAsyncComplete) {
          response.json().then((json) => {
            localStorage.setItem("wopr-session", json.session_id);

            onAsyncComplete(json.message.split("\n"));
          });
        }
      } else {
        if (onAsyncComplete) {
          response.json().then((json) => {
            onAsyncComplete([json.detail]);
          });
        }
      }
    });

    return {
      output: ["$ " + line],
      exitStatus: 0,
      newState: state,
    };
  }

  return {
    output: ["WOPR", "unknown command"],
    exitStatus: 1,
    newState: state,
  };
};

export const jobDesc: (text: string, link:string) => Binary = (text: string, link:string) => {return (line: string, state: any) => {
  if (state.phase === "init") {
    return {
      output: text.split("\n"),
      exitStatus: 0,
      newState: { phase: "ready", url: link },
    };
  }
  if (state.phase === "ready") {
    if (line.toLowerCase() === "y" || line.length === 0) {
      window.location.href = link;
      return { output: ["Connecting..."], exitStatus: 0, newState: state };
    } else {
      return { output: [], exitStatus: 1, newState: {phase: "exit"}};
    }
  }
  return { output: text.split("\n"), exitStatus: 1, newState: state };
}};

const pmcxText = `
 
 
 
# Product Manager, Consumer Experience
 
## What you'll do
- Rapidly prototype and ship opinionated consumer
  product.
- Champion exceptional design and craft.
- Build an engaged user community.
- Drive adoption and retention with a PLG mindset.
- Act as a locomotive internally.
 
## Who you are
We're looking for a PM with 8+ years of experience, deep technical fluency in AI, a proven track record building 0-to-1 consumer products, an opinionated portfolio that demonstrates tackling complex product challenges, and who is both deeply pragmatic and an excellent communicator.
`;
const pmcxUrl = "https://forms.gle/gFV9nPb3MfLPZD22A";


const pmdxText = `
 
 
 
# Product Manager, Developer Experience
 
## What you'll do
- Know the developer tools ecosystem deeply.
- Continuously evolve SDK shape and ergonomics.
- Create exceptionally high-quality documentation.
- Build a thriving developer community.
- Think commercially.
- Act as a locomotive internally.
 
## Who you are
We're looking for someone with 8+ years experience
writing code as either a software engineer or a deeply
technical PM, who’s extremely detail-oriented, has
strong opinions on what makes a great developer
experience, and is both an excellent communicator and
pragmatic problem solver.
`;
const pmdxUrl = "https://forms.gle/UnYiQkCuVS9HPySy7";

const pdaiText = `
 
 
 
# Product Designer, AI
 
## What you'll do
- Define the behavior of an agentic platform.
- Create elegant solutions to complex problems.
- Prototype rapidly with a team of excellent people.
- Build with our users.
 
## Who you are
- 8+ years of UX and UI design experience
- A strategic problem solver
- Proficient in storytelling
- Technically fluent
- Deeply curious
- Systems thinker
`;
const pdaiUrl = "https://forms.gle/QcfwHntJg1F3Ay1u8";

export const pdai: Binary = jobDesc(pdaiText, pdaiUrl);
export const pmcx: Binary = jobDesc(pmcxText, pmcxUrl);
export const pmdx: Binary = jobDesc(pmdxText, pmdxUrl);
