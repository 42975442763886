import React from 'react';

interface Person {
  id: string;
  name: string;
  about: JSX.Element[];
  shuffle?: boolean;
  not_first?: number;
}

export function getFounders(): Person[] {
  const ficusLI = (name: string) => (
    <a
      href="https://www.linkedin.com/in/ficus-kirkpatrick/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  );

  return [
    {
      id: "dps",
      name: "David Singleton",
      about: [
        <p>
          David built smartphone OS software before smartphones were a thing. He
          worked on most of Google's early mobile experiences and founded
          Android Wear. For the last seven years he's been CTO at Stripe
          building and scaling economic infrastructure for the Internet and
          geeking out on developer experience.
        </p>,
        <p>
          David enjoys cooking, skiing, and tinkering with neural networks.
        </p>,
        <a href="https://blog.singleton.io">blog.singleton.io</a>,
        <a href="https://www.linkedin.com/in/davidpsingleton">
          linkedin.com/in/davidpsingleton
        </a>,
      ],
    },
    {
      id: "ficus",
      name: "Ficus Kirkpatrick",
      about: [
        <div>{ficusLI("ファイカス")}は日本語がまあまあできます。</div>,
        <div>
          {ficusLI("Ficus")} probably has too many Wikipedia tabs open right
          now.
        </div>,
        <div>
          {ficusLI("Ficus")} is most commonly found in the mountains, the
          kitchen, or a text editor.
        </div>,
        <div>
          Some people think {ficusLI("Ficus")} is an agentic application
          himself.
        </div>,
        <div>{ficusLI("Ficus")} holds a GED from the state of Washington.</div>,
        <div>
          {ficusLI("Ficus")} helped create the{" "}
          <a href="https://en.wikipedia.org/wiki/Danger_Hiptop">
            first smartphone
          </a>{" "}
          and the{" "}
          <a href="https://en.wikipedia.org/wiki/Android_(operating_system)">
            biggest one
          </a>{" "}
          too.
        </div>,
        <div>
          {ficusLI("Ficus")} once jumped out of an airplane 50 miles from land.
        </div>,
        <div>
          If you have a pair of smartglasses, {ficusLI("Ficus")} probably worked
          on them.
        </div>,
        <div>
          If you have a VR headset, {ficusLI("Ficus")} probably worked on it.
        </div>,
        <div>
          {ficusLI("Ficus")} is trying to make the eigenvalues go up. The right
          ones, anyway.
        </div>,
      ],
      shuffle: true,
      not_first: 0,
    },
    {
      id: "hbarra",
      name: "Hugo Barra",
      about: [
        <p>
          Hugo is a passionate student of computing history and has dedicated a
          career to working on the next major consumer waves — voice assistants,
          smartphones, VR & smartglasses, home diagnostics.
        </p>,
        <p>
          Originally from Brazil, he's lived in the US, UK, China & India and
          explored 82 countries (and counting). His desk is a permanent testing
          ground for prototype hardware and new gadgets.
          Hugo is a proud husband and father of two.
        </p>,
        <a target="_blank" rel="noopener noreferrer" href="https://hugo.blog/">
          hugo.blog
        </a>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/hbarra/"
        >
          linkedin.com/in/hbarra/
        </a>,
      ],
    },
    {
      id: "nj",
      name: "Nicholas Jitkoff",
      about: [
        <p>
          Nicholas loves creating digital experiences that make action feel
          effortless{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://itty.bitty.site/#/eJyN00+LEzEUAPB7P8U7iR62o1Q8tGFA9qon+wXSmcwkNE1K8koZRSile6ogRShi92DZSvVW2UWlVv0wO+mwp/0K0j+Wbbt0vb68/PJ4L49YTCTzcwAYFiNhLB4FXMgQXuUAAHSdBgKTIjzMPy4tI3UahkLFR0bEHIvwiNUW8dc54q0lEjCFzPg5grSyogkan3ho/KVAMPQJL/hlqqHM4JgLFROPFxYp4XbKk8LWwT+D3KSy9jTrDLP29Hr2Zlt4GiA0BXLdQAi1UHHpIJROu1lnmE67e1BTm+pGYlGkDeYPUq53nnWGrnd+2Wq70dhN3rrRBzfp7cllLlQVdATIGdgalRKoBUlNzA4/8PHrvPU5/fnJ/f5+2Wpvo1SFSy9izYVWoyq5o9zT/tXgbN6fuZNv8/fv9qo81ioyWuFSDUUUiaAh8a6puNF4RWYXk+vZYKelXEgGAkFYsCikBEZtcnhAbvQl/dG9Gpyl0677c5L+Op33Z7fVS4NA1+pSWL6sODaMIiC11f/g3Wh8k88uJnv9rSRAwTIjmF2Mbj22AO3hLt9TFVsv7XzyWy7sbIrwny0W5WWDeGKVvw7fR0OVlRSFVlBJ4EUengsMOJPywSZ3YxNvvY7EWy/oX2fgZcc="
            style={{
              fontWeight: 300,
              fontSize: "80%",
              color: "var(--dim-text-color)",
            }}
          >
            &#x70BA;&#x7121;&#x70BA;
          </a>
        </p>,
        <p>
          From OS design at Google and Meta to productivity at Dropbox and
          Figma, he's brought together teams to build software that empowers
          people in new and delightful ways.
        </p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://nicholas.jitkoff.com/"
        >
          nicholas.jitkoff.com
        </a>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/jitkoff/"
        >
          linkedin.com/in/jitkoff
        </a>,
      ],
    },
  ];
}

export function getEmployees(): Person[] {
  const employees: Omit<Person, "shuffle" | "not_first">[] = [
    {
      id: "anthony",
      name: "Anthony Morris",
      about: [
        <p>Anthony is a product-focused engineer interested in open source, delightful user experiences, and the future of technology.</p>,
        <p>When not writing code, you can find him admiring bookshelves, capturing photographs, and learning new things.</p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://anthonymorris.dev"
        >
          anthonymorris.dev
        </a>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/amorriscode"
        >
          linkedin.com/in/amorriscode
        </a>
      ],
    },
    {
      id: "ari",
      name: "Ari Grant",
      about: [
        <p>Ari is engineer-at-heart who worked on mobile and VR at Facebook/Meta for the last 10 years.</p>,
        <p>In his free time he loves to create virtual puzzle games 🧩, watch plays and musicals 🎭🎶, go to amusement parks 🎢, study math and programming 📚, and travel ✈️🚅 (mostly for plays and roller coasters).</p>,
        <p>He believes that every problem eventually reduces to a type-checking / unification problem.</p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/ari-grant/"
        >
          linkedin.com/in/ari-grant
        </a>
      ],
    },
    {
      id: "bjorn",
      name: "Björn Bringert",
      about: [
        <p>
          Björn led the development of Google Search on Android for almost 10
          years, taking it to more than a billion users. After that, he led the
          development of online, connectivity and commercial digital products at
          Volvo Cars.
        </p>,
        <p>
          Lately he has been building everything from electric cargo bikes, sea
          rescue drones and portable electronics to mobile apps, web apps,
          backend services and AI prototypes.
        </p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/bjornbringert"
        >
          linkedin.com/in/bjornbringert
        </a>,
      ],
    },
    {
      id: "eric",
      name: "Eric Kauderer-Abrams",
      about: [
        <p>
          Eric believes that truth knows no disciplinary boundaries. Most recently, he was co-founder & CEO at Detect, where he built systems for rapidly detecting nucleic acids. Before that, he built noninvasive brain interfaces, AI algorithms, and low-power CMOS chips. Whether working with semiconductors, nucleic acids, bits, or companies, he sees it all as an emanation of Linear Algebra. 
        </p>,
        <p>
          Eric loves playing music and hanging out with his guitars and dogs. 
        </p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/eric-kauderer-abrams-0ba5864a/"
        >
          linkedin.com/in/erickabrams
        </a>,
      ],
    },
    {
      id: "h",
      name: "Harrison Kessel",
      about: [
        <p>
          Harrison is trying to bridge the gap between reality and sci-fi. The good kind, at least.
        </p>,
        <p>
          Harrison enjoys hacking on robots, open-source, LLMs, and anything else with bits and bytes.
        </p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/harrisonkessel/"
        >
          linkedin.com/in/harrisonkessel/
        </a>,
      ],
    },
    {
      id: "jake",
      name: "Jake Slack",
      about: [
        <p>
          Jake builds digital ecosystems, and comes from doing that for a decade with Google.
        </p>,
        <p>
          Besides building the future, you will find Jake on the slopes, enjoying fresh pow.
        </p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/jacobslack/"
        >
          linkedin.com/in/jacobslack/
        </a>,
      ],
    },
    {
      id: "kaylee",
      name: "Kaylee George",
      about: [
        <p>Kaylee is an engineer who enjoys working on magical product experiences and learning about systems & cryptography.</p>,
        <p>Outside of clickity-clacking on her HHKB, Kaylee is probably cooking, camping, writing, or playing basketball.</p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://kayleegeorge.github.io"
        >
          kayleegeorge.github.io
        </a>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/kayleegeorge8/"
        >
          linkedin.com/in/kayleegeorge8/
        </a>,
      ],
    },
    {
      id: "sara",
      name: "Sara Rowghani",
      about: [
        <p>Sara is a creative problem-solver who builds brands and experiences that make people’s lives a little easier—and hopefully a lot more fun.</p>,
        <p>When she’s not crafting strategy, she’s crafting furniture, throwing around heavy weights, or hiking with her dog Luna. She’s never met a dad joke she didn’t like, which is pun-ishing all of us.</p>,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/sararowghani"
        >
          linkedin.com/in/sararowghani
        </a>,
      ],
    },
  ];

  return [...employees, {
    id: "you?",
    name: "",
    about: [
      <p>
        Try <b>jobs</b> :-)
      </p>,
    ],
  }].map(p => ({...p, shuffle: false, not_first: -1}));
}